import React, { memo } from "react"
import { path } from "../../lib/util"
import TextRow from "./TextRow"
import ProductsFiltered from "../Products/ProductsFiltered"
import styled from "styled-components"

const ShopPageContent = memo(
  ({
    selected = {},
    currentCategory,
    categoryTitle,
    description,
    descriptionShort,
    location,
  }) => {
    const prevState = path(["state", "prevState"], location)

    return (
      <Container>
        <TopFallback
          descriptionShort={descriptionShort}
          categoryTitle={categoryTitle}
        />
        <ProductsFiltered
          selected={selected}
          currentCategory={currentCategory}
          prevState={prevState}
          location={location}
        />
        <BottomFallback description={description} />
      </Container>
    )
  }
)
export default ShopPageContent

const TopFallback = ({ descriptionShort, categoryTitle }) => {
  if (descriptionShort)
    return <Content dangerouslySetInnerHTML={{ __html: descriptionShort }} />
  return <TextRow heading={categoryTitle} />
}

const BottomFallback = ({ description }) => {
  if (description)
    return (
      <BottomContentWrapper>
        <Content dangerouslySetInnerHTML={{ __html: description }} />
      </BottomContentWrapper>
    )

  return null
}

const Container = styled.div``

const BottomContentWrapper = styled.div`
  padding-top: 10px;
`

const Content = styled.div`
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 40px 40px 40px;
  ul {
    list-style-type: disc;
    margin-left: 1.5rem;
    padding-bottom: 20px;
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: 500;
  }
  a {
    color: #ed1c24;
  }
  h1 {
    padding: 20px 0;
  }
  h2 {
    padding: 20px 0;
  }
  h3,
  h4 {
    padding: 10px 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    white-space: normal;
    td,
    th {
      border: 1px solid #ddd;
      padding: 8px;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    tr:hover {
      background-color: #ddd;
    }
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #1a293c;
      color: white;
    }
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      &.m-hide-1 tr th:nth-child(1),
      &.m-hide-1 tr td:nth-child(1) {
        display: none;
      }
      &.m-hide-2 tr th:nth-child(2),
      &.m-hide-2 tr td:nth-child(2) {
        display: none;
      }
      &.m-hide-3 tr th:nth-child(3),
      &.m-hide-3 tr td:nth-child(3) {
        display: none;
      }
      &.m-hide-4 tr th:nth-child(4),
      &.m-hide-4 tr td:nth-child(4) {
        display: none;
      }
      &.m-hide-5 tr th:nth-child(5),
      &.m-hide-5 tr td:nth-child(5) {
        display: none;
      }
      &.m-hide-6 tr th:nth-child(6),
      &.m-hide-6 tr td:nth-child(6) {
        display: none;
      }
      &.m-hide-7 tr th:nth-child(7),
      &.m-hide-7 tr td:nth-child(7) {
        display: none;
      }
      &.m-hide-8 tr th:nth-child(8),
      &.m-hide-8 tr td:nth-child(8) {
        display: none;
      }
      &.m-hide-9 tr th:nth-child(9),
      &.m-hide-9 tr td:nth-child(9) {
        display: none;
      }
      &.m-hide-10 tr th:nth-child(10),
      &.m-hide-10 tr td:nth-child(10) {
        display: none;
      }
      &.m-hide-11 tr th:nth-child(11),
      &.m-hide-11 tr td:nth-child(11) {
        display: none;
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    padding: 0 20px 20px 20px;
  }
`
