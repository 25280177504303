import React, { memo } from "react"
import styled from "styled-components"
import {
  sizesToChooseFrom,
  comfortLevelsToChooseFrom,
  sortMethodsToChooseFrom,
} from "./ProductsFiltered.jsx"
import FilterItem from "./FilterItem"
import Inner from "../../zzz/layout/pageInner/Inner"
import Rand from "../../zzz/atoms/icons/files/rand.jsx"
import Size from "../../zzz/atoms/icons/files/size.jsx"
import Bed from "../../zzz/atoms/icons/files/bed.jsx"
import MoonStar from "../../zzz/atoms/icons/files/moon-star.jsx"
import StarSolid from "../../zzz/atoms/icons/files/star-solid.jsx"
import { slugify } from "voca"

// Object keys
const TYPE = "type"
const SIZE = "size"
const BRAND = "brand"
const COMFORT = "comfortLevel"
const SORT = "sort"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================
const FilterItems = ({
  selected,
  filters,
  data,
  setTypeFilter,
  setSizesFilter,
  setBrandFilter,
  setComfortLevelFilter,
  setSortState,
}) => {
  // Check if filters are disabled
  const typeDisabled = selected && selected[TYPE]
  const sizeDisabled = selected && selected[SIZE]
  const brandDisabled = selected && selected[BRAND]
  const comfortLevelDisabled = selected && selected[COMFORT]
  const sortDisabled = selected && selected[SORT]

  // These filter options are variable and change depending on the categories on WooCommerce (should perhaps be passed as context)
  var typesToChooseFrom = data.rootCategories.nodes.filter(
    (cat) => cat.slug && cat.slug !== "all-brands"
  )
  typesToChooseFrom.unshift(
    typesToChooseFrom.splice(
      typesToChooseFrom.findIndex((value) => value.name == "Mattresses"),
      1
    )[0]
  )
  typesToChooseFrom.unshift(
    typesToChooseFrom.splice(
      typesToChooseFrom.findIndex((value) => value.name == "Beds"),
      1
    )[0]
  )
  const brandsToChooseFrom = data.brandCategories.nodes

  return (
    <Inner>
      <FilterInner>
        <FilterItem
          label={"Types"}
          filterKey={TYPE}
          items={typesToChooseFrom}
          filterChange={handleFilterChange}
          filter={setTypeFilter}
          filterValues={filters[TYPE]}
          disabled={typeDisabled}
          icon={Bed}
        />
        <FilterItem
          label={"Sizes"}
          filterKey={SIZE}
          items={sizesToChooseFrom}
          filterChange={handleFilterChange}
          filter={setSizesFilter}
          filterValues={filters[SIZE]}
          disabled={sizeDisabled}
          icon={Size}
        />
        <FilterItem
          label={"Brands"}
          filterKey={BRAND}
          items={brandsToChooseFrom}
          filterChange={handleFilterChange}
          filter={setBrandFilter}
          filterValues={filters[BRAND]}
          disabled={brandDisabled}
          icon={StarSolid}
        />
        <FilterItem
          label={"Comfort"}
          filterKey={COMFORT}
          items={comfortLevelsToChooseFrom}
          filterChange={handleFilterChange}
          filter={setComfortLevelFilter}
          filterValues={filters[COMFORT]}
          disabled={comfortLevelDisabled}
          icon={MoonStar}
        />
        <FilterItem
          label={"Sort"}
          filterKey={SORT}
          items={sortMethodsToChooseFrom}
          filterChange={handleSortChange}
          filter={setSortState}
          filterValues={filters[SORT]}
          disabled={sortDisabled}
          icon={Rand}
          filters={filters}
          selectMultiple={false}
        />
      </FilterInner>
    </Inner>
  )
}
export default memo(FilterItems)

// Handle a change in the filters
const handleFilterChange = (event, setFunction) => {
  const newName = slugify(event.target.name)
  setFunction((prev) => {
    if (prev.some((it) => it === newName)) {
      return prev.filter((it) => it !== newName)
    } else {
      return [...prev, newName]
    }
  })
}
// Handle a change in the sort
const handleSortChange = (event, setFunction) => {
  const { name, checked } = event.target
  setFunction((prev) => (checked ? [] : [name]))
}

const FilterInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    border-top: 1px solid ${({ theme }) => theme.colors.midnight["020"]};
    flex-direction: column;
    overflow: hidden;
  }
`
