/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react"
import { graphql } from "gatsby"
import { path } from "../lib/util"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import ShopPageContent from "../components/ShopPage/ShopPageContent"
import ShopPageLayout from "../components/ShopPage/ShopPageLayout"
import { replaceAll } from "voca"

const ShopPage = ({ pageContext, location, data }) => {
  const post_id = pageContext.category && pageContext.category.wordpress_id
  const category_title = pageContext.category
    ? pageContext.category.name
    : "Shop"
  const tablePressTables = pageContext.tablePressTables

  const descriptionTables =
    data && data.localWpGraphQlPages && data.localWpGraphQlPages.content
  const descriptionTablesReplaced = tablePressExpandShortcodes(
    descriptionTables,
    tablePressTables
  )
  const shopPageDescription =
    (data &&
      data.localWpGraphQlPages &&
      data.localWpGraphQlPages.shop_pages &&
      data.localWpGraphQlPages.shop_pages.shopPageShortDescription) ||
    ""

  const seo = data.localWpGraphQlPages ? data.localWpGraphQlPages.seo : {}

  const domainPath = "www.themattresswarehouse.co.za\\/"
  const replaceFullPath = `${domainPath}${pageContext.fullPath
    .replace(/(?:^\/|\/$)/g, "")
    .replace(/\//g, "\\/")}`
  const replaceSlugPath = `${domainPath}${pageContext.slug}`

  seo.yoastMeta = seo.yoastMeta
    ? replaceAll(seo.yoastMeta, replaceSlugPath, replaceFullPath)
    : ""
  seo.yoastSchema = seo.yoastSchema
    ? replaceAll(seo.yoastSchema, replaceSlugPath, replaceFullPath)
    : ""
  seo.woocommerceSchema = seo.woocommerceSchema
    ? replaceAll(seo.woocommerceSchema, replaceSlugPath, replaceFullPath)
    : ""

  return (
    <Layout location={location}>
      <SEO
        title={category_title}
        location={location}
        post_id={post_id}
        seo={seo}
      />
      <ShopPageLayout
        location={location}
        currentCategory={pageContext.category}
        selected={pageContext.selected}
      >
        <ShopPageContent
          currentCategory={pageContext.category}
          selected={pageContext.selected}
          description={descriptionTablesReplaced}
          descriptionShort={shopPageDescription}
          location={location}
        />
      </ShopPageLayout>
    </Layout>
  )
}

export default ShopPage

const pathFixTablePress = (path_array, pageContext) => {
  return tablePressExpandShortcodes(
    path(path_array, pageContext),
    pageContext.tablePressTables
  )
}
// TablePress: This function searches through the input text for TablePress
// shortcodes and replaces them with the appropirate table.
// If the table can't be found the shortcode is just removed
const tablePressExpandShortcodes = (input_text, tables_json_array) => {
  let output_text = input_text
  if (!output_text) {
    output_text = ""
  } else if (tables_json_array) {
    // Replace the shortcodes in the input text according to the array provided
    for (var key of Object.keys(tables_json_array)) {
      output_text = output_text.replace(key, tables_json_array[key])
    }
  }
  return output_text
}

export const pageQuery = graphql`
  query GET_PAGE_FOR_CATEGORY_SEO($slug: String!) {
    localWpGraphQlPages(slug: { eq: $slug }) {
      seo {
        yoastTitle
        yoastDescription
        yoastMeta
        yoastSchema
        woocommerceSchema
      }
      title
      shop_pages {
        shopPageShortDescription
      }
      content
    }
  }
`
